function Footer() {
  return (
    <>
      {" "}
      <footer className="footer">© 2024. Santiago Correa</footer>
    </>
  );
}

export default Footer;
